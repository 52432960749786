import React from "react";
import "../styles/Calendaire.css";

export default function Calendaire() {
     return (
          <div
               style={{
                    padding: "0% 1%",
               }}
          >
               <p
                    style={{
                         textTransform: "uppercase",
                         fontSize: "17px",
                         fontWeight: "500",
                         letterSpacing: "11px",
                         margin: "o%",
                    }}
               >
                    Upcomming
               </p>

               <ul
                    style={{
                         margin: "0%",
                         listStyle: "none",
                         padding: "0%",
                    }}
               >
                    {/* <li>
                         11/03/2025 LIFE electronics, Émilie Girard-Charest, le
                         Phoenix à Valenciennes (FR)
                    </li> */}
                    <li>
                         12/04/2025 - Infused 3'600'000ms, collaborating with
                         Goran Nježić on a new multimedia project, commissioned
                         from Music Biennale Zagreb, premiered by Elision
                         ensemble as part of MBZ '25 (HR).
                    </li>
                    <li>
                         29/05/2025 - _a.7_D!_Y_f1L!//g_🦄_ New multimedia piece
                         - to be premiered by flock-lab at scena Ribnjak in
                         Zagreb, Croatia (HR).
                    </li>
                    <li>
                         30/10/2025 - D.i.s._i.s._j.u.s.t._t.h.a._i.n.p.u.t. -
                         New multimedia experience - to be premiered by
                         flock-lab at Maison de l'Argentine à Paris (FR)
                    </li>
                    <li>
                         24,25,26/04/2026 - 1UP, new performance with
                         coreographie by Ria Rehfuss for The Interstring Project
                         (DE).
                    </li>

                    {/* <li>
                         13/01/2025 - Infused 3'600'000ms (reduced version), new
                         multimedia project, as part of ICST pre-PhD program at
                         ZHdK (CH), premiered by Joshua Hyde + Francisco Uberto.
                    </li> */}
                    {/* <li>
                         24/10/2024 All my neurons have clits, Flock Lab,
                         Ambassade de l'Argentine à Paris, Paris (FR)
                    </li>
                    <li>
                         17/10/2024 All my neurons have clits, Flock Lab, CRD de
                         Pantin, Pantin (FR)
                    </li> */}

                    {/* <li>
                         28/06/24 1Up - solo set, Gallerie du Haut Pavé, Paris
                         (FR)
                    </li> */}
                    {/* <li>
                         15/05/24 K.A.M.I.K.A.Z.E. B-L duo at Kater Blau in
                         Berlin as part of classicalNEXT '24(DE)
                    </li>
                    <li>
                         27/04/24 K.A.M.I.K.A.Z.E. B-L duo at The music parlour
                         (city hall) Singapore (SG)
                    </li> */}
                    {/* <li>
                         15/03/24 Paradise (or how I'll get there from a door on
                         the third floor), salle d'orchestre à la HEAR,
                         Strasbourg (FR)
                    </li>
                    <li>
                         02/02/24 - LIFE electronics. mastering at studio "la
                         cave à son" (+ info soon), Paris (FR)
                    </li>
                    <li>
                         11/01/24 - All by myself - solo set performance -
                         Maison de l'Argentine - Paris (FR)
                    </li>

                    <li>
                         25/11/23 - K.A.M.I.K.A.Z.E - B-L duo - hcfm// festival
                         (UK)
                    </li>
                    <li>
                         11/11/23 - P.A.R.A.D.I.S.E. - Li Li-Chin - C-Lab -
                         Taipei (TW)
                    </li>
                    <li>
                         26/10/23 - Elettrificare il Rosa - Trio Métallique -
                         Cité International des Arts, Paris (FR)
                    </li> */}
               </ul>
          </div>
     );
}
